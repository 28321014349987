exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-abrir-minha-empresa-dados-pessoais-index-jsx": () => import("./../../../src/pages/abrir-minha-empresa-dados-pessoais/index.jsx" /* webpackChunkName: "component---src-pages-abrir-minha-empresa-dados-pessoais-index-jsx" */),
  "component---src-pages-bpo-financeiro-index-jsx": () => import("./../../../src/pages/bpo-financeiro/index.jsx" /* webpackChunkName: "component---src-pages-bpo-financeiro-index-jsx" */),
  "component---src-pages-cadastro-obrigado-index-jsx": () => import("./../../../src/pages/cadastro-obrigado/index.jsx" /* webpackChunkName: "component---src-pages-cadastro-obrigado-index-jsx" */),
  "component---src-pages-contabilidade-digital-index-jsx": () => import("./../../../src/pages/contabilidade-digital/index.jsx" /* webpackChunkName: "component---src-pages-contabilidade-digital-index-jsx" */),
  "component---src-pages-contabilidade-digital-para-ecommerce-index-jsx": () => import("./../../../src/pages/contabilidade-digital-para-ecommerce/index.jsx" /* webpackChunkName: "component---src-pages-contabilidade-digital-para-ecommerce-index-jsx" */),
  "component---src-pages-contabilidade-digital-para-pj-ti-index-jsx": () => import("./../../../src/pages/contabilidade-digital-para-pj-ti/index.jsx" /* webpackChunkName: "component---src-pages-contabilidade-digital-para-pj-ti-index-jsx" */),
  "component---src-pages-contabilidade-digital-para-servicos-digitais-index-jsx": () => import("./../../../src/pages/contabilidade-digital-para-servicos-digitais/index.jsx" /* webpackChunkName: "component---src-pages-contabilidade-digital-para-servicos-digitais-index-jsx" */),
  "component---src-pages-contabilidade-digital-para-startups-index-jsx": () => import("./../../../src/pages/contabilidade-digital-para-startups/index.jsx" /* webpackChunkName: "component---src-pages-contabilidade-digital-para-startups-index-jsx" */),
  "component---src-pages-falar-especialista-dados-pessoais-index-jsx": () => import("./../../../src/pages/falar-especialista-dados-pessoais/index.jsx" /* webpackChunkName: "component---src-pages-falar-especialista-dados-pessoais-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-index-jsx": () => import("./../../../src/pages/login/index.jsx" /* webpackChunkName: "component---src-pages-login-index-jsx" */),
  "component---src-pages-politica-de-privacidade-index-jsx": () => import("./../../../src/pages/politica-de-privacidade/index.jsx" /* webpackChunkName: "component---src-pages-politica-de-privacidade-index-jsx" */)
}

